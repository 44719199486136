import React, { FunctionComponent } from "react";

const Article: FunctionComponent = ({ children }) => {
  return (
    <div className="tablet:bg-grey-light">
      <div className="container mx-auto tablet:px-4 tablet:py-8 laptop:pb-24">
        <div className="tablet:px-4 bg-white tablet:shadow-xl">{children}</div>
      </div>
    </div>
  );
};

export default Article;
