import React, { FunctionComponent } from "react";

const Headline: FunctionComponent = ({ children }) => {
  return (
    <hgroup className="title uppercase py-3 tablet:py-0 tablet:relative tablet:-ml-8 tablet:-mr-4 z-10">
      {children}
    </hgroup>
  );
};

export default Headline;
