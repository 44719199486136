import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Article from "../../components/Article";
import Headline from "../../components/Headline";

import BrochureEnglish from "../../components/Markup/BrochureEnglish";
import HorizontalRule from "../HorizontalRule";
import Link from "../Link";
import Image from "../Image";

type BrochureProps = {
  language: string;
  brochureURL: string;
  copy: { [key: string]: string };
  languageCode: string;
  previewImage: any;
};

const Brochure: FunctionComponent<BrochureProps> = ({
  brochureURL,
  copy,
  language,
  languageCode,
  previewImage,
}) => {
  const data = useStaticQuery(graphql`
    query {
      downloadIcon: file(base: { eq: "download-pdf.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <Article>
      <Headline>
        <h1>{language}</h1>
      </Headline>
      <div className="laptop:hidden pt-8">
        <Link
          download
          href={brochureURL}
          className="flex items-center justify-center"
        >
          <div className="text-green underline font-medium mr-4">
            {language}
          </div>
          <img src={data.downloadIcon.publicURL} />
        </Link>
      </div>
      <div lang={languageCode}>
        <div className="py-12 px-4 flex flex-col-reverse laptop:flex-row">
          <div className="hidden laptop:block laptop:w-2/5 mt-4">
            <Image
              fluid={previewImage.childImageSharp.fluid}
              width={281}
              className="mx-auto drop-shadow"
            />
            <div className="pt-8">
              <Link
                download
                href={brochureURL}
                className="flex items-center justify-center"
              >
                <img src={data.downloadIcon.publicURL} />
                <div className="text-green underline font-medium mx-4">
                  {language}
                </div>
              </Link>
            </div>
          </div>
          <div className="w-full laptop:w-3/5">
            <p className="py-4">{copy["hep-b-serious-liver-disease"]}</p>
            <p className="text-red py-2">{copy["inside-brochure"]}</p>
            <div className="py-4">
              <ul className="bullet black pl-2">
                <li>{copy["how-chb-affects-liver"]}</li>
                <li>{copy["how-hepb-spread"]}</li>
                <li>{copy["testing-and-vaccination"]}</li>
                <li>{copy["how-chb-treated"]}</li>
              </ul>
            </div>
            <div className="laptop:hidden py-8 ">
              <Image
                fluid={previewImage.childImageSharp.fluid}
                width={158}
                className="mx-auto drop-shadow"
              />
              <div className=" pt-8">
                <Link
                  download
                  href={brochureURL}
                  className="flex items-center justify-center"
                >
                  <div className="text-green underline font-medium mr-4">
                    {language}
                  </div>
                  <img src={data.downloadIcon.publicURL} />
                </Link>
              </div>
            </div>
            <HorizontalRule />
            <BrochureEnglish />
          </div>
        </div>
      </div>
    </Article>
  );
};
export default Brochure;
