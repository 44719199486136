import React, { FunctionComponent } from "react";

/* 
 * English copy deliberately included in this file.
 * 
 * We don't have translations for this text in kor/tch/sch/vie
 * Rather than modify the user's i18next language setting, 
 * we'll just show them the bottom half in English
 * 
 */

const BrochureEnglish: FunctionComponent = () => {
  return (
    <div>
      <p className="py-4">
        Hepatitis B (or hep B) is a serious liver disease that affects millions of people across the world. This brochure is here to help you better understand hep B and learn how to manage it.
      </p>
      <p className="text-red py-2">
        Inside this brochure, we will explore the following topics:
      </p>
      <ul className="bullet black pl-2">
        <li>
          How chronic hep B affects the liver
        </li>
        <li>
          How hep B is spread
        </li>
        <li>
          Testing and vaccination
        </li>
        <li>
          How chronic hep B is treated
        </li>
      </ul>
    </div>
  );
};

export default BrochureEnglish;
